<template>
  <div
    v-if="!isMediumScreen"
    class="vms-project-sidebar vd-border-light-grey vd-padding-top-medium vd-padding-right-small vd-padding-left-small"
  >
    <div
      v-for="(routeData, index) in routesData"
      :key="index"
      class="vms-project-sidebar--menu vms-project-sidebar-border vd-padding-bottom-medium vd-margin-bottom-medium vd-border-light-grey"
    >
      <project-sidebar-desktop
        :route-data="routeData"
      ></project-sidebar-desktop>
    </div>
  </div>

  <div v-else class="vd-padding-medium">
    <project-sidebar-mobile
      v-for="(routeData, index) in routesData"
      :key="index"
      :route-data="routeData"
    ></project-sidebar-mobile>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectSidebarDesktop from '@views/project/components/project-sidebar/project-sidebar-desktop'
import ProjectSidebarMobile from '@views/project/components/project-sidebar/project-sidebar-mobile'

export default {
  components: {
    ProjectSidebarDesktop,
    ProjectSidebarMobile,
  },

  props: {
    sidebarType: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isMediumScreen: 'common/isMediumScreen',
    }),

    routesData() {
      switch (this.sidebarType) {
        case 'invoices':
          return this.invoicesRoutesData
        case 'uploadedFiles':
          return this.uploadedFilesRoutesData
        default:
          return []
      }
    },

    invoicesRoutesData() {
      return [
        {
          id: 'viewInvoicesOverviewLink',
          to: 'project.details.invoices.overview',
          label: 'Overview',
          icon: 'icon-circle-info',
        },
        {
          id: 'viewInvoicesListLink',
          to: 'project.details.invoices.list',
          label: 'Invoices',
          icon: 'icon-credits',
        },
        {
          id: 'viewInvoiceBudgetLink',
          to: 'project.details.invoices.budget',
          label: 'Budget',
          icon: 'icon-money-check-dollar-pen',
        },
        {
          id: 'viewInvoiceHubSpotQuotesLink',
          to: 'project.details.invoices.hubspot-quotes',
          label: 'HubSpot quotes',
          icon: 'icon-envelope-open-dollar',
        },
      ]
    },

    uploadedFilesRoutesData() {
      return [
        {
          id: 'viewProjectFilesLink',
          to: 'project.details.uploaded-files.project-files',
          label: 'Project Files',
          activeIcon: 'vd-icon-book-read-blue',
          defaultIcon: 'vd-icon-book-read-grey',
        },
        {
          id: 'viewProjectBrandHubLink',
          to: 'project.details.uploaded-files.brand-hub',
          label: 'Brand Hub',
          activeIcon: 'vd-icon-building-blue',
          defaultIcon: 'vd-icon-building',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/sidebar';
</style>
