<template>
  <router-link
    :id="routeData.id"
    :to="{ name: routeData.to }"
    :class="[
      'vms-project-sidebar--menu__link vd-margin-right-medium vd-padding-bottom-extra-small vd-a',
      $route.name === routeData.to
        ? 'active vd-light-blue vd-border-light-blue vd-border-bottom-width-3 vd-border-width-0'
        : 'vd-darker-grey',
    ]"
  >
    {{ routeData.label }}
  </router-link>
</template>

<script>
export default {
  props: {
    routeData: {
      type: Object,
      required: true,
    },
  },
}
</script>
