<template>
  <router-link
    :id="routeData.id"
    :to="{ name: routeData.to }"
    :class="[
      'vms-project-sidebar--menu__link',
      $route.name === routeData.to ? 'active vd-light-blue' : 'vd-darker-grey',
    ]"
  >
    <i
      :class="[
        'vd-icon vd-h4 vd-margin-right-extra-small vd-align-middle',
        routeData.icon,
        $route.name === routeData.to ? 'vd-blue' : 'vd-black',
      ]"
    ></i>
    <span class="vd-align-middle vd-h4">{{ routeData.label }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    routeData: {
      type: Object,
      required: true,
    },
  },
}
</script>
