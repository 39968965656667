<template>
  <div
    :class="[
      'vms-invoices',
      isMobileScreen ? 'vms-invoices--mobile' : 'vms-content__container',
    ]"
  >
    <project-sidebar
      v-if="!isMediumScreen"
      sidebar-type="invoices"
    ></project-sidebar>
    <div class="vms-invoices__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectSidebar from '@views/project/components/project-sidebar/project-sidebar'

export default {
  components: {
    ProjectSidebar,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
    }),
  },
}
</script>
<style lang="scss">
@import '@styles/views/project/invoices';
</style>
